<template>
  <bg-style v-imloading="loading" :bg="model.background" :class="{ 'mobile': isMobile }" class="sign-activity px-5 py-8">
    <div v-if="model.titleVisible" class="sign-activity__title">
      <rich-text v-model="model.title" :editing="editing" :class="$store.getters.isDesktop && 'xl:text-[40px]'" class="text-[20px] w-full title" :disabled="!editing" theme="snow" />
    </div>
    <div v-if="model.subtitleVisible" class="sign-activity__subtitle mt-4">
      <rich-text v-model="model.subtitle" :editing="editing" :disabled="!editing" theme="snow" />
    </div>
    <div class="sign-activity__info flex justify-between mt-6 relative text-18">
      <div>{{ $t('siteBuild.signWidget.tip1') }} {{ signData.has_sign_in_days }}</div>
      <div>{{ $t('siteBuild.signWidget.tip2') }} {{ signData.remain_appending_days }}</div>
    </div>
    <div class="sign-activity__scroll mt-4">
      <div class="sign-activity__list relative z-10">
        <bg-style v-for="(item, index) in signData.sign_in_list" :key="index" :bg="model.signBackground" class="item" @click.native="handleSignIn(item)">
          <bg-style v-if="(index === 0 && editing) || item.is_sign_in" class="item-mark h-full z-30" :bg="model.doneBackground" />
          <bg-style v-if="(index === 1 && editing) || item.is_appending" class="item-mark h-full z-30" :bg="model.repairBackground" />
          <bg-style v-if="(index === 2 && editing) || item.is_available_sign_in" class="item-mark h-full z-30" :bg="model.canBackground" />
          <div class="item-day z-10">{{ index + 1 }}d</div>
          <div class="item-pic z-20 overflow-hidden">
            <img class="w-full h-full" :src="item.gift_goods_logo" alt="">
          </div>
          <div class="item-number z-10">{{ item.num }}</div>
        </bg-style>
      </div>
    </div>
    <div class="sign-activity__info relative text-18 mt-6">{{ $t('siteBuild.signWidget.tip3') }}</div>
    <div class="sign-activity__scroll mt-4">
      <div class="sign-activity__list relative z-10">
        <bg-style v-for="(item, index) in signData.consecutive_list" :key="index" :bg="model.signBackground" class="item" :class="{ 'active': item.is_get }" @click.native="handleGetGift(item)">
          <bg-style v-if="(index === 0 && editing) || item.is_sign_in" class="item-mark h-full z-30" :bg="model.doneBackground" />
          <bg-style v-if="(index === 1 && editing) || item.is_get" class="item-mark h-full z-30" :bg="model.canBackground" />
          <div class="item-day z-10">{{ item.sign_in_consecutive_days }}</div>
          <div class="item-pic z-20 overflow-hidden">
            <img class="w-full h-full" :src="item.gift_goods_logo" alt="">
          </div>
          <div class="item-number z-10">{{ item.num }}</div>
        </bg-style>
      </div>
    </div>
  </bg-style>
</template>

<script>
import {mapState} from "vuex";
import moment from "moment/moment"
import {Message} from "element-ui";
import isNil from "lodash.isnil";
import RichText from '~/components/richText/index.vue'
import {DeviceEnum} from '~/enums/deviceEnum'
import Bus from "~/site/model/bus";

export default {
  name: 'SignActivityWidget',
  components: {RichText},
  props: {
    model: {
      type: Object,
      default() {
        return {}
      }
    },
    editing: {
      type: Boolean,
      default: false
    },
    device: {
      type: String,
      default: 'desktop'
    }
  },
  data() {
    return {
      loading: false,
      activityData: {
        start_time: 0
      },
      signData: {
        remain_appending_days: 0,
        has_sign_in_days: 0,
        consecutive_list: [],
        sign_in_list: []
      },
      signList: []
    }
  },
  computed: {
    isMobile() {
      return this.device === DeviceEnum.MOBILE
    },
    ...mapState({
      userInfo: (state) => state.user.siteUserInfo,
      projectId: (state) => state.project.projectId,
      siteId: (state) => state.project.info.id
    })
  },
  beforeDestroy() {
    Bus.$off('reloadActivity')
  },
  mounted() {
    this.init()
  },
  methods: {
    async init() {
      if (this.editing) {
        await this.getActivityData()
      }
      Bus.$on('reloadActivity', async () => {
        await this.getActivityData()
        await this.getSingData()
      })
    },
    checkUser() {
      let result = true
      if (isNil(this.userInfo?.id)) {
        this.$store.commit('user/SET_LOGINSHOW', !this.editing)
        this.$store.commit('user/SET_SITE_BIND_USER', true)
        this.$logger?.captureMessage('点击购买，未登录，弹出登录弹窗')
        result = false
      } else if (this.editing) {
        result = false
      }
      return result
    },
    async handleGetGift({ sign_in_consecutive_days, is_get, is_sign_in  }) {
      if (!is_get || is_sign_in) return
      if (!this.checkUser()) return
      const params = {
        activity_id: this.activityData.biz_id,
        sign_in_type: 3,
        consecutive_day: sign_in_consecutive_days,
        site_id: this.siteId
      }
      const [err] = await this.$utils.to(this.$api.activity.signIn(params))
      if (!err) {
        Message.success('Success')
        await this.getSingData()
      } else {
        Message.error(err)
      }
    },
    async handleSignIn({ is_appending, day_no, is_available_sign_in }) {
      if (!this.checkUser()) return
      if (!is_available_sign_in && !is_appending) return
      const params = {
        activity_id: this.activityData.biz_id,
        sign_in_type: 1,
        site_id: this.siteId,
      }
      if (is_appending) {
        params.sign_in_type = 2
        params.appending_date = moment(this.activityData.start_time * 1000).add(day_no, 'days').utc().format('YYYY-MM-DD')
        params.day_no = Number(day_no)
      }
      this.loading = true
      const [err] = await this.$utils.to(this.$api.activity.signIn(params))
      this.loading = false
      if (!err) {
        Message.success('Success')
        await this.getSingData()
      } else {
        Message.error(err)
      }
    },
    async getSingData() {
      if (!this.userInfo) return
      const params = {
        activity_id: this.activityData.biz_id,
        page_size: 365,
        site_id: this.siteId,
        page_no: 1
      }
      this.loading = true
      const [err, res] = await this.$utils.to(this.$api.activity.querySignActivity(params))
      this.loading = false
      if (!err) {
        this.signData = res
      }
    },
    async getActivityData() {
      this.loading = true
      const now_stem = Math.floor(moment().utc().valueOf() / 1000)
      const params = {
        now_time: now_stem,
        player_id: (this.userInfo && this.userInfo.uid || '').trim(),
        project_id: this.projectId || this.userInfo.project_id || '',
        activity_type: 4,
        status: 2
      }
      const [err, res] = await this.$utils.to(this.$api.activity.getActivityList(params, {editing: this.editing}))
      this.loading = false
      if (!err) {
        this.activityData = res.list ? res.list[0] : { activity_rule: '' }
        this.activityData.activity_rule = JSON.parse(this.activityData.activity_rule || '{}')
      }
      if (this.editing || !this.userInfo) {
        const { gift_list, consecutive_gift_list } = this.activityData.activity_rule
        this.signData.sign_in_list = gift_list
        this.signData.consecutive_list = consecutive_gift_list
      }
    }
  }
}
</script>

<style lang="less">
.sign-activity {
  &__title, &__subtitle {
    max-width: 1280px;
    margin: 0 auto;
    position: relative;
  }

  &__info {
    max-width: 1280px;
    margin: 0 auto;
  }

  &__scroll {
    max-height: 260px;
    max-width: 1280px;
    margin: 0 auto;
    overflow: auto;
    position: relative;
    &::-webkit-scrollbar-thumb {
      display: block;
      background-color: #DEDFE0;
    }
  }
  &__list {
    display: flex;
    row-gap: 16px;
    column-gap: 46px;
    border-radius: 10px;
    flex-wrap: wrap;
    padding-left: 1px;
    .item {
      width: 100px;
      height: 100px;
      border-radius: 8px;
      overflow: hidden;
      position: relative;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;
      &.active {
        //border-color: greenyellow;
      }
      &-mark {
        position: absolute !important;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
      &-pic {
        width: 50px;
        height: 50px;
        position: relative;
      }
      &-number {
        text-align: center;
        z-index: 10;
        //position: absolute;
        //bottom: 10px;
        //right: 10px;
      }
      &-day {
        font-weight: bold;
        text-align: center;
        //position: absolute;
        //top: 20%;
        //left: 50%;
        //transform: translate(-50%, -50%);
      }
      &-name {
        //background: linear-gradient(255deg, #C83B57 3%, #871A30 100%);
        line-height: 36px;
        color: white;
        font-size: 12px;
      }
    }
  }

  &.mobile {
    .sign-activity__scroll {
      max-height: 200px;
    }
    .sign-activity__title,.sign-activity__info,.sign-activity__subtitle {
      width: 100%;
    }
    .sign-activity__list {
      width: 100%;
      gap: 4px;
      padding-left: 0;
      .item {
        width: 24%;
        height: 80px;
        &-pic {
          width: 40px;
          height: 40px;
        }
      }
    }
  }
}
</style>
