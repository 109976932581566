<template>
  <div class="dialog-edit">
    <menu-item label="蒙板背景">
      <menu-fill :target="model.background" target-key="background" />
    </menu-item>
    <menu-item label="弹窗背景">
      <menu-background-image :target="model.contentBackground" target-key="contentBackground" />
      <menu-fill class="mt-2" :target="model.contentBackground" target-key="contentBackground" />
    </menu-item>
    <menu-item label="弹窗圆角">
      <menu-slider v-model="model.radius" target-key="radius" />
    </menu-item>
    <menu-switch v-model="model.loadVisible" class="mt-4" label="是否开屏显示" target-key="loadVisible" />
    <menu-switch v-model="model.buttonVisible" class="mt-4" label="是否显示按钮" target-key="loadVisible" />
    <menu-item v-if="model.buttonVisible" label="按钮名称">
      <base-input v-model="model.buttonText" size="small" />
    </menu-item>
    <menu-item label="弹窗板块">
      <div class="dialog-edit__list">
        <div v-for="(item, index) in model.list" :key="index" class="item rounded-sm">
          <div class="flex items-center justify-between w-full">
            <div class="text-12">板块{{ index + 1 }}</div>
            <i class="el-icon-delete cursor-pointer hover:text-danger" @click.stop="handleRemove(index)"/>
          </div>
          <menu-item class="pt-4" label="图片" size-tip="建议比例(4:3)">
            <menu-background-image :target="item.background" :event-key="`dialog-image-${index}`" />
          </menu-item>
<!--          <menu-item label="按钮名称">-->
<!--            <base-input v-model="item.buttonText" size="small" />-->
<!--          </menu-item>-->
        </div>
      </div>
    </menu-item>
    <menu-button v-if="model.list.length < model.__maxLength" class="mt-4" icon="add" @click.native="handleAdd">添加弹窗板块</menu-button>
  </div>
</template>


<script>
import MenuItem from "~/components/menu/menuItem.vue"
import MenuFill from "~/components/menu/menuFill.vue"
import MenuBackgroundImage from "~/components/menu/menuBackgroundImage.vue"
import MenuSlider from "~/components/menu/menuSlider.vue"
import MenuSwitch from "~/components/menu/menuSwitch.vue"
// import BaseInput from "~/components/base/baseInput.vue";
import MenuButton from "~/components/menu/menuButton.vue";
import BaseInput from "~/components/base/baseInput.vue";

export default {
  name: 'DialogWidgetEdit',
  components: {
    BaseInput,
    MenuButton,
    // BaseInput,
    MenuSwitch,
    MenuSlider,
    MenuBackgroundImage,
    MenuFill,
    MenuItem
  },
  props: {
    model: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
    }
  },
  methods: {
    handleRemove(index) {
      this.model.list.splice(index, 1)
      this.$nextTick(() => {
        this.model.__refresh()
      })
    },
    handleAdd() {
      this.model?.__addItem()
      this.$nextTick(() => {
        this.model.__refresh()
      })
    }
  }
}
</script>

<style lang="less">
.dialog-edit {
  &__list {
    .item {
      background-color: @fill-color-2;
      padding: 12px 10px 12px 8px;
      margin-bottom: 16px;
    }
  }
}
</style>
