<template>
    <div v-show="visible" class="im-loading-wrap">
        <div class="loading-content">
            <img src="./loading.gif" alt="">
        </div>
    </div>
</template>

<script>
export default {
    data () {
        return {
            visible: true,
        }
    }
}
</script>
<style lang="less" scoped>
    .im-loading-wrap {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        //background-color: rgba(0,0,0, 0.2);
        z-index: 2021;
        .loading-content {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            img {
                min-width: 20px;
                min-height: 20px;
                max-width: 40px;
                max-height: 40px;
            }
        }
    }
</style>
