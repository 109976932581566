<template>
  <SiteIndex v-if="!native && model" :model="model" :white-list="[]"/>
  <site-shop-cart v-else-if="(native === PageNameEnum.SHOP_CART) && model" :model="model"></site-shop-cart>
</template>

<script>
import SiteCore, {postReady, getSiteBuilderDataByService, initPwa} from "~/site/core"
import SiteIndex from "~/site/components/siteIndex"
import SiteShopCart from "~/site/components/shopCartPage/index.vue";
import {useAssets} from "~/hooks/useAssets";
import tool from '~/utils';
import langConfigMixins from "~/mixins/langConfigMixins";
import {PageNameEnum} from "~/enums/pageEnum";
import {insertScript} from "~/utils/dom"

export default {
  name: "SitePreview",
  components: {
    SiteIndex,
    SiteShopCart
  },
  mixins: [langConfigMixins],
  layout: 'defaultLayout',
  async asyncData({redirect, app, params, route, store}) {
    return await getSiteBuilderDataByService({redirect, app, route, params, store})
  },
  data() {
    return {
      native: null,
      model: null,
      lang: '',
      mid: '',
      pid: '',
      domain: '',
      pLang: '',
      siteBuildId: '',
      siteInfo: {},
      baseInfo: {},
      pageList: [],
      web: {},
      pwa: {},
      languages: []
    }
  },
  head() {
    const site = this.siteInfo.conf.web.site
    return {
      title: site?.seoSetting.title || '',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: site?.seoSetting.desc || '',
        },
        {
          hid: 'viewport',
          name: 'viewport',
          content: 'width=device-width,initial-scale=1,user-scalable=no',
        },
      ],
      link: [
        {
          rel: 'icon',
          hid: 'favicon.ico',
          type: 'image/x-icon',
          href: site?.seoSetting.favicon || '',
        }
      ]
    }
  },
  computed: {
    PageNameEnum() {
      return PageNameEnum
    }
  },
  beforeDestroy() {
    this.pwa.off()
  },
  mounted() {
    const {setSiteLangList} = useAssets(this)
    setSiteLangList(this.langList)
    this.setNewUrl()
    this.pwa = initPwa(this)
  },
  methods: {
    setConsole() {
      insertScript({
        src: 'https://unpkg.com/vconsole@latest/dist/vconsole.min.js'
      }, () => {})
    },
    // 设置语言
    async setNewUrl() {
      const params = tool.getNeedParamsFormRouteParams(this.$route.params)
      this.native = params.native
      if (!params.lang && !params.native) {
        const {params, path} = this.$route
        const {sid, pageid} = tool.getNeedParamsFormRouteParams(params)
        const prefix = path.includes('/preview') ? `/preview/${sid}` : ''
        const page_id = pageid === '/' ? '' : `${pageid}`
        const sysLang = tool.getSysLanguage()
        const langArr = this.langList?.map(item => item.code) || []
        const curLang = langArr.includes(sysLang) ? sysLang : this.siteInfo.default_language
        this.pLang = curLang
        const fullPath = `${prefix}/${curLang}/${page_id}`
        await this.$router.push(fullPath)
      } else {
        this.pLang = params.lang
        await this.init()
      }
      this.$cookies.set('lang', this.pLang)
      tool.setFavicon(this.siteInfo.conf.web.site?.seoSetting.favicon)
    },
    async init() {
      try {
        this.$store.commit('editor/SET_EDIT_MODE', false)
        const {baseInfo, siteInfo, languages, langList, pageList} = this
        const lang = this.pLang || this.siteInfo.default_language
        const langIdData = await this.getLangConfig(lang, this.siteInfo.default_language, this.pid)
        this.model = new SiteCore({
          editMode: false,
          baseInfo,
          siteInfo: siteInfo.conf,
          languages,
          langList,
          lang,
          langIdData,
          pageList,
        })
        this.$store.commit('locale/SET_LANG', this.pLang || this.siteInfo.default_language)
        this.$store.commit('locale/SET_LANG_DATA', langIdData)
        this.$nextTick(() => {
          postReady()
        })
      } catch (error) {
        console.error(error)
        return Promise.reject(error)
      }
    },

  }
}
</script>
